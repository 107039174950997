import Gal1 from "../../img/gallery/1.jpg";
import Gal2 from "../../img/gallery/2.jpg";
import Gal3 from "../../img/gallery/3.jpg";
import Gal4 from "../../img/gallery/4.jpg";
import "./Gallery.scss";

const Gallery = () => {
  return (
    <div className="container">
      <div className="gal-photo gal-photo-1">
        {" "}
        <img src={Gal1} alt="harmony" />
      </div>
      <div className="gal-photo gal-photo-2">
        {" "}
        <img src={Gal2} alt="harmony" />
      </div>
      <div className="gal-photo gal-photo-3">
        {" "}
        <img src={Gal3} alt="harmony" />
      </div>
      <div className="gal-photo gal-photo-4">
        {" "}
        <img src={Gal4} alt="harmony" />
      </div>
    </div>
  );
};

export default Gallery;

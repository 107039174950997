const PriceList = () => {
  return (
    <div className="priceList-container main-component-content">
      <div className="offer-description priceList">
        <div className="offer-theme priceList title">CENNIK</div>
        <div className="offer-theme priceList">
          PIERWSZA WIZYTA - 250 zł <br />( czas około 1,5 godz.)
        </div>
        <div className="offer-description-text">
          Diagnostyka + odpowiednio dobrany program terapeutyczny + zalecenia
        </div>

        <div className="offer-theme priceList">
          TERAPIA BIOREOZONANSEM – 100 zł <br /> (czas około 1 godzina)
        </div>
        <div className="offer-description-text">
          Odpowiednio dobrany program terapeutyczny (m. in. usuwanie patogenów,
          alergenów, detoksykacja itp.) + terapia wzmacniająca
        </div>

        <div className="offer-theme priceList">
          TERAPIE UZALEŻNIEŃ: ANTYNIKOTYNOWA, ANTYALKOHOLOWA, SŁODYCZE – 150 zł{" "}
          <br />
          (czas około 60 minut)
        </div>
        <div className="offer-description-text">
          Terapia i odtruwanie organizmu
        </div>
      </div>
    </div>
  );
};

export default PriceList;

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-header">ODKRYJ MOC BIOREZONANSU </div>
      <div>TEL. 797 710 746</div>
      <div>UL. ŚWIERZAWSKA 1</div>
      <div>60-321 POZNAŃ</div>
    </div>
  );
};

export default Footer;

import './Biorezonans.scss';

const Biorezonans = () => {
  return (
    <>
      <div className="offer-description main-component-content">
        <div className="offer-description-header">
          Oferta BioStrefy obejmuje różnorodne usługi w dziedzinie biorezonansu.
          Poniżej znajduje się przegląd dostępnych usług:{' '}
        </div>
        <div className="offer-theme">Diagnostyka Biorezonansem</div>
        <div className="offer-description-text">
          Usługa diagnostyki biorezonansem pozwala na dokładne badanie różnych
          aspektów zdrowia przy użyciu nowoczesnych urządzeń biorezonansowych.
          Dzięki precyzyjnym analizom, dostarczamy informacji, które mogą pomóc
          zidentyfikować potencjalne obszary do poprawy.
        </div>
        <div className="offer-theme">Terapia Biorezonansem</div>
        <div className="offer-description-text">
          Oferta obejmuje terapię biorezonansem, która działa na zasadzie
          stymulacji naturalnych procesów samouzdrawiania organizmu. Ta forma
          terapii może być wykorzystywana w przypadku różnych problemów
          zdrowotnych, mając na celu przywrócenie równowagi organizmu.
        </div>
        <ul className="offer-list-container">
          <li className="offer-theme list-item">
            Testy Alergiczne i Odczulanie
          </li>
          <li className="offer-theme list-item">
            Testy Identyfikacji Obciążeń
          </li>
          <li className="list-item item">
            Wykrywanie obciążeń pasożytami, grzybami, pleśniami, wirusami i
            innymi czynnikami
          </li>
          <li className="offer-theme list-item">Test Boreliozy</li>
          <li className="offer-theme list-item">Obciążenia Środowiskowe</li>
          <li className="offer-theme list-item">Usuwanie Obciążeń Patogenów</li>
          <li className="offer-theme list-item">
            Terapie Przy Chorobach Przewlekłych
          </li>
          <li className="offer-theme list-item">Terapie Przeciwbólowe</li>
          <li className="offer-theme list-item">Terapie Przeciwzapalne</li>
          <li className="offer-theme list-item">
            Wsparcie przy różnych stanach zapalnych, takich jak zapalenie zatok,
            zapalenie gardła, zapalenie stawów
          </li>
          <li className="offer-theme list-item">Terapie Chorobowe</li>
          <li className="offer-theme list-item">
            Zabiegi terapeutyczne w przypadku konkretnych chorób, takich jak
            astma oskrzelowa, miażdżyca, zaćma, zwyrodnienie plamki żółtej,
            arytmia serca, choroba Raynauda, AZS, SIBO
          </li>
          <li className="offer-theme list-item">Usuwanie Toksyn</li>
          <li className="offer-theme list-item">Wzmacnianie Odporności</li>
          <li className="offer-theme list-item">Oczyszczanie Krwi</li>
          <li className="offer-theme list-item">
            Kompleksowa Diagnostyka Organizmu
          </li>
          <li className="list-item item">
            Analiza wszystkich organów i układów organizmu ludzkiego
          </li>
          <li className="list-item item">
            Wykrywanie infekcji narządów i układów: wirusy, bakterie, grzyby,
            pierwotniaki, zakażenia robakami itp.
          </li>
          <li className="list-item item">
            Ocena jakościowa poziomu hormonów, w tym nadnercza, przysadka
            mózgowa, trzustka, tarczyca, gruczoły płciowe
          </li>
          <li className="list-item item">Indywidualny dobór leków</li>
        </ul>
      </div>
    </>
  );
};

export default Biorezonans;

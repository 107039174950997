import './AboutMe.scss';
import AboutMePhoto from '../../img/me-pic-1.jpg';

const AboutMe: React.FC = () => {
  return (
    <div className="about-me-section" style={{ minHeight: '695px' }}>
      <div className="photo-section">
        <div className="photo">
          <img className="about-me-photo" src={AboutMePhoto} alt="harmony" />
          <div className="tile-info">
            <div className="name">mgr DANUTA SZUMIŃSKA</div>
            <div>
              Terapeuta biorezonansu magnetycznego BICOM oraz Biophilia Tracker
              <br />
              nauczyciel wychowania fizycznego
              <br />
              trener personalny, instruktor fitness
            </div>
          </div>
        </div>
        <div className="text-section">
          <div className="name">KILKA SŁÓW O MNIE</div>
          <div>
            <div className="text">
              Jestem specjalistką zajmująca się diagnozą oraz terapią za pomocą
              urządzeń biorezonansu. Moja pasja do medycyny niekonwencjonalnej,
              zdrowego stylu życia i aktywności fizycznej skłoniła mnie do
              pogłębiania wiedzy przez studia oraz liczne kursy, dzięki którym
              zdobyłam umiejętności patrzenia na pacjenta w sposób holistyczny.
              Uwzględniam wszystkie aspekty życia, które mogą wpływać na zdrowie
              i dobre samopoczucie.
            </div>
            <div className="text">
              Wpływ na poprawę zdrowia moich pacjentów daje mi niesamowitą
              satysfakcję i utwierdza w przekonaniu, że nasz organizm ma ogromne
              zdolności do samouzdrawiania, a tak naprawdę jedynym czego
              potrzebuje jest impuls i zainicjowanie odpowiednich warunków ku
              temu.
            </div>
            <div className="text">
              Celem mojej pracy jest przywrócenie organizmowi odpowiednich
              warunków do regeneracji i poprawnego funkcjonowania. Jestem
              ukierunkowana na postawienie właściwej diagnozy, dotarcie do
              źródła problemu i wyeliminowanie go. Podchodzę indywidualnie do
              każdego pacjenta, co prowadzi do spersonalizowanej i skutecznej
              terapii.
            </div>
            <div className="text">
              Zapraszam Cię do mojego gabinetu biorezonansu, gdzie wspólnie
              zadbamy o Twoje zdrowie i harmonię.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;

import BiorezonansPhoto from '../../../img/gallery/photo-1.jpg';
import BiorezonansPhoto1 from '../../../img/gallery/photo-2.jpg';
import './Therapy.scss';

const Therapy = () => {
  return (
    <div className="min-height-view">
      <div className="therapy-main-content">
        <div className="therapy-text">
          Jako jedyny gabinet w Poznaniu oferuję Państwu unikalną diagnozę oraz
          terapię opartą na dwóch zaawansowanych urządzeniach, które są uważane
          za jedne z najbardziej precyzyjnych i skutecznych na świecie.
          Pierwszym z nich jest Bicom renomowanej niemieckiej firmy REGUMED
          GmbH, zapewniający niezrównaną jakość diagnostyki i terapii. Drugim
          jest Biophilia Tracker X4, który w połączeniu z Bicom tworzy
          kompleksowy zestaw narzędzi. To unikalne połączenie obu urządzeń daje
          ogromne możliwości w identyfikacji źródeł problemów zdrowotnych oraz w
          ich skutecznym leczeniu. Oferta BioStrefy to nie tylko medycyna
          przyszłości, to terapia, która naprawdę działa.
        </div>
        <div className="therapy-img-container">
          <img
            className="therapy-img therapy-img-1"
            src={BiorezonansPhoto}
            alt="harmony"
          />
          <img
            className="therapy-img therapy-img-2"
            src={BiorezonansPhoto1}
            alt="harmony"
          />
        </div>
      </div>
    </div>
  );
};

export default Therapy;

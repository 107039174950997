import './Home.scss';
import HomePhoto from '../../img/home-photo.jpg';
import LogotypColor from '../../img/logotyp-color.png';

const Home: React.FC = () => {
  return (
    <>
      <div className="section-1">
        <img className="home-photo" src={LogotypColor} alt="harmony" />
        <div className="theme-line-container text-container">
          <div className="theme-line-text theme-line-content">
            Zapraszam do mojego gabinetu, w którym znajdziesz naturalną pomoc w
            diagnostyce i terapii swojego ciała. Moją misją jest wspracie
            powrotu do zdrowia przez zainicjowanie procesu samouzdrowienia oraz
            dobranie indywidulanych terapii.
          </div>
          <div className="theme-line">
            <div>PAMIĘTAJ, ZDROWIE JEST </div>
            <div>NAJWAŻNIEJSZĄ RZECZĄ W ŻYCIU</div>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="text-container">
          <div className="theme-line-title">DIAGNOSTYKA</div>
          <div className="theme-line-content content">
            Diagnostyka biorezonansem to innowacyjna metoda badania, która
            pozwala na analizę energii i informacji obecnych w organizmie.
            Pozwala wykryć ewentualne zaburzenia energetyczne czy
            nieprawidłowości w funkcjonowaniu różnych narządów i układów.
          </div>
          <div className="theme-line-title">TERAPIA</div>
          <div className="theme-line-content content">
            Terapia skupia się na harmonizowaniu i wzmocnieniu energetycznego
            pola ciała. Poprzez analizę i optymalizację rezonansu
            elektromagnetycznego, terapia biorezonansem może pomóc w wykrywaniu
            i redukcji nieprawidłowości energetycznych, które mogą przyczyniać
            się do problemów zdrowotnych.
          </div>
        </div>
        <img className="home-photo-2" src={HomePhoto} alt="harmony" />
      </div>
    </>
  );
};

export default Home;

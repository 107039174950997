export const biorezonansItemName = 'O METODZIE';
export const offerItemName = 'URZĄDZENIA';
export const questionsItemName = 'FAQ';

export const OfferMenuItemNames = {
  offer: offerItemName,
  biorezonans: biorezonansItemName,
  questions: questionsItemName,
} as const;

export type OfferMenuItemName =
  | typeof biorezonansItemName
  | typeof offerItemName
  | typeof questionsItemName;

export const offerMenuItems = [
  { id: '1', category: 'offer', name: OfferMenuItemNames.offer },
  { id: '2', category: 'biorezonans', name: OfferMenuItemNames.biorezonans },
  { id: '3', category: 'questions', name: OfferMenuItemNames.questions },
];

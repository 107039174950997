import "./Contact.scss";
import Gal5 from "../../img/gallery/5.jpg";

const Contact = () => {
  const phoneNumber = "+48 797 710 746";
  return (
    <div className="main-container" style={{ minHeight: "695px" }}>
      <div className="contact-info">
        <div className="contact-theme-line">
          <div>SKONTAKTUJ SIĘ ZE MNĄ</div>
          <div>I ZRÓB PIERWSZY KROK KU UZDROWIENIU</div>
        </div>
        <div className="contact-text-container">
          <div className="contact-text">
            TEL. <a href={`tel:${phoneNumber}`}> {phoneNumber}</a>
          </div>
          <div className="contact-text">UL. ŚWIERZAWSKA 1</div>
          <div className="contact-text">60-321 POZNAŃ</div>
        </div>
      </div>

      <div className="map-container">
        <iframe
          title="BioStrefa"
          width="720"
          height="400"
          frameBorder="0"
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.475073045986!2d16.835128977046526!3d52.37993764658947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704455a82b9dc73%3A0xc46e68273059bba1!2sBioStrefa%20-%20Gabinet%20Biorezonansu!5e0!3m2!1spl!2spl!4v1693072730229!5m2!1spl!2spl"
        />
      </div>
      <div className="contact-photo">
        <img src={Gal5} alt="contact" />
      </div>
    </div>
  );
};

export default Contact;
